<template>
	<div class="flex" style="flex-direction: column; height: 100%">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col v-if="rolePermissions" :span="6">
						<el-form-item label="发布企业：">
							<el-select v-model="searchParm.entId" clearable :popper-append-to-body="false" placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="6">
						<el-form-item label="知识类型：">
							<el-select v-model="searchParm.type" collapse-tags clearable :popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in knowledgeTypeData" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right"><el-button type="primary" size="medium" @click="uploadDialog()" style="margin-right: 8px">上传文件</el-button></div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px"
						>
							<!-- <el-table-column type="selection" align="center" width="55">
							</el-table-column> -->
							<el-table-column width="750" prop="name" align="center" label="文档名称" show-overflow-tooltip></el-table-column>
							<el-table-column v-if="rolePermissions" prop="entName" align="center" label="发布企业" show-overflow-tooltip></el-table-column>
							<el-table-column prop="type" align="center" label="类型" show-overflow-tooltip :formatter="typeFormatter"></el-table-column>
							<el-table-column
								prop="modify_time"
								align="center"
								label="发布时间"
								show-overflow-tooltip
								:formatter="
									(row, column) => {
										let data = row.modify_time;

										if (data === '') {
											return '';
										} else {
											let dt = new Date(data);
											return (
												dt.getFullYear() +
												'-' +
												(dt.getMonth() + 1) +
												'-' +
												dt.getDate() +
												' ' +
												dt.getHours() +
												':' +
												(dt.getMinutes() > 10 ? dt.getMinutes() : '0' + dt.getMinutes()) +
												':' +
												dt.getSeconds()
											);
										}
									}
								"
							></el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button type="text" @click="look(scope.row)">查看</el-button>
									<el-button type="text" @click="edit(scope.row)">编辑</el-button>
									<el-button type="text" @click="remove(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
		<look :isShow.sync="showLookDialog" :dialogObj="lookObj"></look>
		<upload :isShow.sync="showUploadDialog"></upload>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import edit from './edit.vue';
import look from './look.vue';
import upload from './upload.vue';
export default {
	components: {
		smCard,
		edit,
		look,
		upload
	},
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				size: 20,
				current: 1
			},
			// batchActionType: '',
			showEditDialog: false,
			showLookDialog: false,
			showUploadDialog: false,
			editObj: {},
			lookObj: {},
			knowledgeTypeData: [],
			rolePermissions: true, //判断当前角色是否企业角色

			ents: []
		};
	},
	created() {
		this.getKnowledgeTypeData();
		this.getEnts();
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//获取企业列表
		getEnts() {
			this.$get('/backend-api/sys/ent/list', {
				size: 99
			}).then(res => {
				if (res.code == 1000) {
					this.ents = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
			this.list();
		},
		//知识类型
		getKnowledgeTypeData() {
			this.knowledgeTypeData = [
				{
					id: '1',
					name: '设备维护手册'
				},
				{
					id: '2',
					name: '设备管理制度'
				},
				{
					id: '3',
					name: '安全管理制度'
				},
				{
					id: '4',
					name: '设备规程'
				},
				{
					id: '5',
					name: '典型故障'
				},
				{
					id: '6',
					name: '检修SOP'
				}
			];
		},
		typeFormatter(row) {
			let data = parseInt(row.type);
			let typeName = '';
			switch (data) {
				case 1:
					typeName = '设备维护手册';
					break;
				case 2:
					typeName = '设备管理制度';
					break;
				case 3:
					typeName = '安全管理制度';
					break;
				case 4:
					typeName = '设备规程';
					break;
				case 5:
					typeName = '典型故障';
					break;
				case 6:
					typeName = '检修SOP';
					break;
				default:
					typeName = '';
					break;
			}

			return typeName;
		},
		//列表数据
		list() {
			this.$get('/backend-api/eqp/doc/list', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData.records = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//打开查看弹窗
		look(row) {
			this.lookObj = row;
			this.showLookDialog = true;
		},
		//打开上传弹窗
		uploadDialog() {
			this.showUploadDialog = true;
		},
		//打开编辑弹窗
		edit(row) {
			this.editObj = row;
			this.showEditDialog = true;
		},
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		//点击账户启用状态
		changeSwitch(val, row) {
			this.$post('/user-api/ent/user/status/update', {
				id: row.id,
				status: val
			}).then(res => {
				if (res.code == 1000) {
					this.$message.success(res.msg);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//删除
		remove(row) {
			this.$confirm('是否确认删除？', '删除知识手册', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('/backend-api/eqp/doc/delete', {
						id: row.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		}
	},
	mounted() {
		this.list();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
