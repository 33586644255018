<template>
  <el-dialog class="dialog" title="查看" :visible.sync="visiable">
    <div v-loading="loading" class="dlalog-body">
      <el-form ref="form" :model="formData" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="20">
            <el-form-item label="标题" prop="title">
              <el-input v-model="formData.name" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col :span="12">
            <el-form-item label="所属企业" prop="ent">
              <el-select disabled v-model="formData.entId" placeholder="请选择">
                <el-option
                  v-for="item in ents"
                  :key="item.id"
                  :label="item.abbr"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="知识类型" prop="knowledgeType">
              <el-select disabled v-model="formData.type" placeholder="请选择">
                <el-option
                  v-for="item in knowledgeTypeData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
				          
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="images">
          <div class="fileTitle">附件列表</div>
          <el-upload
            class="upload-demo"
            action=""
			      disabled
            :on-preview="filePreview"
            :file-list="fileList"
          >
            
          </el-upload>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()">关闭</el-button>
        <el-button type="primary"  @click="close()">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { dialogMixin } from "@m/dialogMixin";
import configApi from "@config/configApi";
export default {
  mixins: [dialogMixin],
  data() {
    return {
      title: "",
      loading: false,
      fileList: [],
      formData: {},
      ents: [],
      newFile: new FormData(),
      roles: [],
      knowledgeTypeData: [],
      knowledgeType: "",
      rules: {
        title: {
          required: true,
          message: "此项必填",
          trigger: "blur",
        },
      },
    };
  },
  props: {},
  
  watch: {},
  
  methods: {
    //打开弹窗
    open() {
      this.formData = {};
      this.fileList = [];
      this.getEnts();
      this.getDepartments();
      this.formData = this.dialogObj;
      // console.log(this.formData.url.split('/'));
      let urlName = this.formData.url.split('/')[this.formData.url.split('/').length-1];
      
      this.fileList[0] = {name:urlName,url: configApi.photoURL + this.formData.url};
     
      this.headUrl = "";
      this.departments = [];
      this.roles = [];
      
    
    },
    //预览
    filePreview (data) {
      console.log(data);
      window.open(data.url);
    },
      //获取企业列表
			getEnts() {
				this.$get("/backend-api/sys/ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
    //获取知识类型
    getDepartments() {
        this.knowledgeTypeData = [
         {
          id: 1,
          name: "设备维护手册",
        },
        {
          id: 2,
          name: "设备管理制度",
        },
        {
          id: 3,
          name: "安全管理制度",
        },
        {
          id: 4,
          name: "设备规程",
        },
        {
          id: 5,
          name: "典型故障",
        },
        {
          id: 6,
          name: "检修SOP",
        },
      ];
    },
    //获取数据
    get() {},
    //关闭弹窗
    close() {
      this.visiable = false;
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog {
  ::v-deep.cascader {
    display: block;
  }

  ::v-deep.el-image {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .images {
    margin: 10px 25px;
    .fileTitle {
      color: #303133;
      font {
        size: 16px;
        weight: bold;
      }
     }
  }
}

.avatar-uploader {
  display: inline-block;
  margin-right: 20px;

  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }

  ::v-deep .el-upload:hover {
    border-color: #409eff;
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
