<template>
  <el-dialog class="dialog" title="上传" :visible.sync="visiable">
    <div v-loading="loading" class="dlalog-body">
      <el-form ref="form" :model="formData" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="20">
            <el-form-item label="标题" prop="name">
              <el-input v-model="formData.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col :span="12">
            <el-form-item label="所属企业" prop="entId">
              <el-select v-model="formData.entId" placeholder="请选择">
                <el-option
                  v-for="item in ents"
                  :key="item.id"
                  :label="item.abbr"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="知识类型" prop="type">
              <el-select v-model="formData.type" placeholder="请选择">
                <el-option
                  v-for="item in knowledgeTypeData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="images">
           <el-form-item label="附件" prop="url">
             <el-upload
                class="upload-demo"
                action=""
                :http-request="Upload"
                :before-upload="BeforeUpload"
                :on-remove="handleRemove"
                :limit="1"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  dox/docx/pdf/jpg/png格式，不超过10MB，最多限上传1个
                </div>
              </el-upload>
            </el-form-item>
          
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()">关闭</el-button>
        <el-button type="primary" @click="submit()">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { dialogMixin } from "@m/dialogMixin";
import configApi from "@config/configApi";
export default {
  mixins: [dialogMixin],
  data() {
    var validateAttach = (rule, value, callback) => {
        if (!this.formData.url) {
          callback(new Error('附件不能为空'))
        } else {
          callback()
        }
      };
    return {
      title: "",
      loading: false,
      
      formData: {
        
      },
      fileList:[],
      ents: [],
      newFile: new FormData(),
      
      knowledgeTypeData: [],
      rules: {
        name: {
          required: true,
          message: "请填写名称",
          trigger: "blur",
        },
         url: {
         validator: validateAttach,
          trigger: "blur",
        },
         type: {
          
          required: true,
          message: "请选择知识类型",
          trigger: "change",
        },
         entId: {
          
          required: true,
          message: "请选择企业",
          trigger: "change",
        }
      },
    };
  },
  props: {},
  watch: {},
  methods: {
    //打开弹窗
    open() {
      this.formData = {};
      this.fileList = [];
      this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
      this.getDepartments();
      this.getEnts();
    },
    //附件删除
     handleRemove(file, fileList) {
        console.log(file, fileList);
        this.fileList = fileList;
        this.formData.url = "";
      },
      //获取企业列表
			getEnts() {
				this.$get("/backend-api/sys/ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
   
    //获取知识类型
    getDepartments() {
       this.knowledgeTypeData = [
         {
          id: 1,
          name: "设备维护手册",
        },
        {
          id: 2,
          name: "设备管理制度",
        },
        {
          id: 3,
          name: "安全管理制度",
        },
        {
          id: 4,
          name: "设备规程",
        },
        {
          id: 5,
          name: "典型故障",
        },
        {
          id: 6,
          name: "检修SOP",
        },
      ];
    },
    //获取数据
    get() {},
    //上传前校验
    BeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      if (fileType != ".jpg" && fileType != ".png" && fileType != ".pdf" && fileType != ".dox"&& fileType != ".docx") {
        this.$message.error("上传文件只能是 dox/docx/pdf/jpg/png 格式!");
      } else if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      } else {
        this.currentFileName = file.name;
        if (file) {
          if(this.newFile.has("file")){
          	this.newFile.delete("file");
          }
          this.newFile.append("file", file);
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    //手动上传
    Upload(param) {
      
      this.loading = true;
      this.$uploadWithName(this.newFile)
        .then((res) => {
          if (res.code == 1000) {
            this.formData.url = res.data;
            this.headUrl = configApi.photoURL + res.data;
            this.loading = false;
            this.$message.success(res.msg);
          } else {
            this.loading = false;
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });

    },
    //提交表单
    submit() {
      if(this.formData.url){
        console.log(this.formData.url);
        this.$refs["form"].clearValidate(["url"]);
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$postData("/backend-api/eqp/doc/save", this.formData).then(
            (res) => {
              if (res.code == 1000) {
                this.loading = false;
                this.$message.success(res.msg);
                this.$parent.list();
                this.close();
              } else {
                this.loading = false;
                this.$message.error(res.msg);
              }
            }
          );
        }
      });
    },
    //关闭弹窗
    close() {
      this.visiable = false;
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog {
  ::v-deep.cascader {
    display: block;
  }

  ::v-deep.el-image {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .images {
    margin: 10px 25px;
  }
}

.avatar-uploader {
  display: inline-block;
  margin-right: 20px;

  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }

  ::v-deep .el-upload:hover {
    border-color: #409eff;
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
